@import "./Utils/additional-variables.scss";
/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
    font-family: "Open Sans", sans-serif;
    color: #444444;
  }
  
  a {
    color: $base-accent;
    text-decoration: none;
  }
  
  a:hover {
    color: #469fdf;
    text-decoration: none;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Raleway", sans-serif;
  }
  
  /*--------------------------------------------------------------
  # Back to top button
  --------------------------------------------------------------*/
  .back-to-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: 15px;
    z-index: 996;
    background: $base-accent;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    transition: all 0.4s;
  }
  
  .back-to-top i {
    font-size: 28px;
    color: $base-white;
    line-height: 0;
  }
  
  .back-to-top:hover {
    background: #3e9bdd;
    color: $base-white;
  }
  
  .back-to-top.active {
    visibility: visible;
    opacity: 1;
  }
  
  /*--------------------------------------------------------------
  # Preloader
  --------------------------------------------------------------*/
  #preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    overflow: hidden;
    background: $base-white;
  }
  
  #preloader:before {
    content: "";
    position: fixed;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    border: 6px solid $base-accent;
    border-top-color: $base-white;
    border-bottom-color: $base-white;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: animate-preloader 1s linear infinite;
  }
  
  @keyframes animate-preloader {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  /*--------------------------------------------------------------
  # Disable aos animation delay on mobile devices
  --------------------------------------------------------------*/
  @media screen and (max-width: 768px) {
    [data-aos-delay] {
      transition-delay: 0 !important;
    }
  }
  
  /*--------------------------------------------------------------
  # Header
  --------------------------------------------------------------*/
  #header {
    transition: all 0.5s;
    background: $base-white;
    z-index: 997;
    padding: 15px 0;
    border-bottom: 1px solid #e6f2fb;
  }
  
  #header.header-scrolled {
    border-color: $base-white;
    box-shadow: 0px 2px 15px rgba(18, 66, 101, 0.08);
  }
  
  #header .logo {
    font-size: 28px;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-weight: 300;
    letter-spacing: 0.5px;
    font-family: "Poppins", sans-serif;
  }
  
  #header .logo a {
    color: #16507b;
  }
  
  #header .logo img {
    max-height: 40px;
  }
  
  @media (max-width: 992px) {
    #header .logo {
      font-size: 28px;
    }
  }
  
  /*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
  /**
  * Desktop Navigation 
  */
  .navbar {
    padding: 0;
  }
  
  .navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }
  
  .navbar li {
    position: relative;
  }
  
  .navbar a,
  .navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0 10px 30px;
    font-size: 14px;
    color: #16507b;
    white-space: nowrap;
    transition: 0.3s;
  }
  
  .navbar a i,
  .navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
  }
  
  .navbar a:hover,
  .navbar .active,
  .navbar .active:focus,
  .navbar li:hover>a {
    color: $base-accent;
  }
  
  .navbar .getstarted,
  .navbar .getstarted:focus {
    background: $base-accent;
    padding: 8px 20px;
    margin-left: 30px;
    border-radius: 4px;
    color: $base-white;
  }
  
  .navbar .getstarted:hover,
  .navbar .getstarted:focus:hover {
    color: $base-white;
    background: $base-blue;
  }
  
  .navbar .dropdown ul {
    display: block;
    position: absolute;
    left: 14px;
    top: calc(100% + 30px);
    margin: 0;
    padding: 10px 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    background: $base-white;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: 0.3s;
    border-radius: 4px;
  }
  
  .navbar .dropdown ul li {
    min-width: 200px;
  }
  
  .navbar .dropdown ul a {
    padding: 10px 20px;
    text-transform: none;
  }
  
  .navbar .dropdown ul a i {
    font-size: 12px;
  }
  
  .navbar .dropdown ul a:hover,
  .navbar .dropdown ul .active:hover,
  .navbar .dropdown ul li:hover>a {
    color: $base-accent;
  }
  
  .navbar .dropdown:hover>ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
  }
  
  .navbar .dropdown .dropdown ul {
    top: 0;
    left: calc(100% - 30px);
    visibility: hidden;
  }
  
  .navbar .dropdown .dropdown:hover>ul {
    opacity: 1;
    top: 0;
    left: 100%;
    visibility: visible;
  }
  
  @media (max-width: 1366px) {
    .navbar .dropdown .dropdown ul {
      left: -90%;
    }
  
    .navbar .dropdown .dropdown:hover>ul {
      left: -100%;
    }
  }
  
  /**
  * Mobile Navigation 
  */
  .mobile-nav-toggle {
    color: $base-dark-teal;
    font-size: 28px;
    cursor: pointer;
    display: none;
    line-height: 0;
    transition: 0.5s;
  }
  
  .mobile-nav-toggle.bi-x {
    color: $base-white;
  }
  
  @media (max-width: 991px) {
    .mobile-nav-toggle {
      display: block;
    }
  
    .navbar ul {
      display: none;
    }
  }
  
  .navbar-mobile {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(10, 38, 58, 0.9);
    transition: 0.3s;
    z-index: 999;
  }
  
  .navbar-mobile .mobile-nav-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
  }
  
  .navbar-mobile ul {
    display: block;
    position: absolute;
    top: 55px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 10px 0;
    background-color: $base-white;
    overflow-y: auto;
    transition: 0.3s;
  }
  
  .navbar-mobile a,
  .navbar-mobile a:focus {
    padding: 10px 20px;
    font-size: 15px;
    color: $base-dark-teal;
  }
  
  .navbar-mobile a:hover,
  .navbar-mobile .active,
  .navbar-mobile li:hover>a {
    color: $base-accent;
  }
  
  .navbar-mobile .getstarted,
  .navbar-mobile .getstarted:focus {
    margin: 15px;
  }
  
  .navbar-mobile .dropdown ul {
    position: static;
    display: none;
    margin: 10px 20px;
    padding: 10px 0;
    z-index: 99;
    opacity: 1;
    visibility: visible;
    background: $base-white;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  }
  
  .navbar-mobile .dropdown ul li {
    min-width: 200px;
  }
  
  .navbar-mobile .dropdown ul a {
    padding: 10px 20px;
  }
  
  .navbar-mobile .dropdown ul a i {
    font-size: 12px;
  }
  
  .navbar-mobile .dropdown ul a:hover,
  .navbar-mobile .dropdown ul .active:hover,
  .navbar-mobile .dropdown ul li:hover>a {
    color: $base-accent;
  }
  
  .navbar-mobile .dropdown>.dropdown-active {
    display: block;
  }
  
  /*--------------------------------------------------------------
  # Hero Section
  --------------------------------------------------------------*/
  #hero {
    width: 100%;
    height: 100vh;
    position: relative;
    // background: url("./Images/bg-6.jpg") top center;
    background-size: cover;
    position: relative;
  }
  
  #hero:before {
    content: "";
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }
  
  #hero .container {
    padding-top: 80px;
  }
  
  #hero h1 {
    margin: 0;
    font-size: 56px;
    font-weight: 700;
    line-height: 72px;
    color: $base-dark-teal;
    font-family: "Poppins", sans-serif;
  }
  
  #hero h2 {
    color: #5e5e5e;
    margin: 10px 0 0 0;
    font-size: 22px;
  }
  
  #hero .btn-get-started {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.5px;
    display: inline-block;
    padding: 14px 50px;
    border-radius: 5px;
    transition: 0.5s;
    margin-top: 30px;
    color: $base-white;
    background: $base-accent;
  }
  
  #hero .btn-get-started:hover {
    background: $base-blue;
  }
  
  #hero .icon-boxes {
    margin-top: 100px;
  }
  
  #hero .icon-box {
    padding: 50px 30px;
    position: relative;
    overflow: hidden;
    background: $base-white;
    box-shadow: 0 0 29px 0 rgba(18, 66, 101, 0.08);
    transition: all 0.3s ease-in-out;
    border-radius: 8px;
    z-index: 1;
  }
  
  #hero .icon-box .title {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 18px;
  }
  
  #hero .icon-box .title a {
    color: $base-dark-teal;
    transition: 0.3s;
  }
  
  #hero .icon-box .description {
    font-size: 15px;
    line-height: 28px;
    margin-bottom: 0;
  }
  
  #hero .icon-box .icon {
    margin-bottom: 20px;
    padding-top: 10px;
    display: inline-block;
    transition: all 0.3s ease-in-out;
    font-size: 36px;
    line-height: 1;
    color: $base-accent;
  }
  
  #hero .icon-box:hover {
    transform: scale(1.08);
  }
  
  #hero .icon-box:hover .title a {
    color: $base-accent;
  }
  
  @media (min-width: 1024px) {
    #hero {
      background-attachment: fixed;
    }
  }
  
  @media (max-height: 800px) {
    #hero {
      height: auto;
    }
  }
  
  @media (max-width: 992px) {
    #hero {
      height: auto;
    }
  
    #hero h1 {
      font-size: 28px;
      line-height: 36px;
    }
  
    #hero h2 {
      font-size: 18px;
      line-height: 24px;
    }
  }
  
  /*--------------------------------------------------------------
  # Sections General
  --------------------------------------------------------------*/
  section {
    padding: 80px 0;
    overflow: hidden;
  }
  
  .section-bg {
    background-color: #f8fbfe;
  }
  
  .section-title {
    text-align: center;
    padding-bottom: 30px;
  }
  
  .section-title h2 {
    font-size: 32px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;
    padding-bottom: 0;
    color: $base-dark-teal;
  }
  
  .section-title p {
    margin-bottom: 0;
    font-size: 14px;
    color: #919191;
  }
  
  /*--------------------------------------------------------------
  # About
  --------------------------------------------------------------*/
  .about .content h3 {
    font-weight: 600;
    font-size: 26px;
  }
  
  .about .content ul {
    list-style: none;
    padding: 0;
  }
  
  .about .content ul li {
    padding-left: 28px;
    position: relative;
  }
  
  .about .content ul li+li {
    margin-top: 10px;
  }
  
  .about .content ul i {
    position: absolute;
    left: 0;
    top: 2px;
    font-size: 20px;
    color: $base-accent;
    line-height: 1;
  }
  
  .about .content p:last-child {
    margin-bottom: 0;
  }
  
  .about .content .btn-learn-more {
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 5px;
    transition: 0.3s;
    line-height: 1;
    color: $base-accent;
    animation-delay: 0.8s;
    margin-top: 6px;
    border: 2px solid $base-accent;
  }
  
  .about .content .btn-learn-more:hover {
    background: $base-accent;
    color: $base-white;
    text-decoration: none;
  }
  
  /*--------------------------------------------------------------
  # Counts
  --------------------------------------------------------------*/
  .counts {
    padding: 60px 0;
  }
  
  .counts .count-box {
    width: 100%;
    text-align: center;
  }
  
  .counts .count-box span {
    font-size: 48px;
    line-height: 48px;
    display: block;
    font-weight: 700;
    color: $base-dark-teal;
    margin-left: 80px;
    margin: auto;
  }
  
  .counts .count-box p {
    padding: 8px 0 0 0;
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: #1e6ca6;
  }
  
  /*--------------------------------------------------------------
  # About Video
  --------------------------------------------------------------*/
  .about-video .content {
    font-size: 15px;
  }
  
  .about-video .content h3 {
    font-weight: 700;
    font-size: 24px;
    color: $base-dark-teal;
  }
  
  .about-video .content ul {
    list-style: none;
    padding: 0;
  }
  
  .about-video .content ul li {
    padding-bottom: 10px;
    padding-left: 28px;
    position: relative;
  }
  
  .about-video .content ul i {
    font-size: 24px;
    color: $base-accent;
    position: absolute;
    left: 0;
    top: -2px;
  }
  
  .about-video .content p:last-child {
    margin-bottom: 0;
  }
  
  .about-video .play-btn {
    width: 94px;
    height: 94px;
    background: radial-gradient($base-accent 50%, rgba(36, 135, 206, 0.4) 52%);
    border-radius: 50%;
    display: block;
    position: absolute;
    left: calc(50% - 47px);
    top: calc(50% - 47px);
    overflow: hidden;
  }
  
  .about-video .play-btn::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid $base-white;
    z-index: 100;
    transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  
  .about-video .play-btn::before {
    content: "";
    position: absolute;
    width: 120px;
    height: 120px;
    animation-delay: 0s;
    animation: pulsate-btn 2s;
    animation-direction: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: steps;
    opacity: 1;
    border-radius: 50%;
    border: 5px solid rgba(36, 135, 206, 0.7);
    top: -15%;
    left: -15%;
    background: rgba(198, 16, 0, 0);
  }
  
  .about-video .play-btn:hover::after {
    border-left: 15px solid $base-accent;
    transform: scale(20);
  }
  
  .about-video .play-btn:hover::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border: none;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid $base-white;
    z-index: 200;
    animation: none;
    border-radius: 0;
  }
  
  @keyframes pulsate-btn {
    0% {
      transform: scale(0.6, 0.6);
      opacity: 1;
    }
  
    100% {
      transform: scale(1, 1);
      opacity: 0;
    }
  }
  
  /*--------------------------------------------------------------
  # Services
  --------------------------------------------------------------*/
  .services .icon-box {
    text-align: center;
    padding: 70px 20px 80px 20px;
    transition: all ease-in-out 0.3s;
    background: $base-white;
    box-shadow: 0px 5px 90px 0px rgba(110, 123, 131, 0.05);
  }
  
  .services .icon-box .icon {
    margin: 0 auto;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: ease-in-out 0.3s;
    position: relative;
  }
  
  .services .icon-box .icon i {
    font-size: 36px;
    transition: 0.5s;
    position: relative;
  }
  
  .services .icon-box .icon svg {
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .services .icon-box .icon svg path {
    transition: 0.5s;
    fill: #f5f5f5;
  }
  
  .services .icon-box h4 {
    font-weight: 600;
    margin: 10px 0 15px 0;
    font-size: 22px;
  }
  
  .services .icon-box h4 a {
    color: $base-dark-teal;
    transition: ease-in-out 0.3s;
  }
  
  .services .icon-box p {
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
  }
  
  .services .icon-box:hover {
    border-color: $base-white;
    box-shadow: 0px 0 35px 0 rgba(0, 0, 0, 0.08);
  }
  
  .services .iconbox-blue i {
    color: $base-blue;
  }
  
  .services .iconbox-blue:hover .icon i {
    color: $base-white;
  }
  
  .services .iconbox-blue:hover .icon path {
    fill: $base-blue;
  }
  
  .services .iconbox-orange i {
    color: #ffa76e;
  }
  
  .services .iconbox-orange:hover .icon i {
    color: $base-white;
  }
  
  .services .iconbox-orange:hover .icon path {
    fill: #ffa76e;
  }
  
  .services .iconbox-pink i {
    color: #e80368;
  }
  
  .services .iconbox-pink:hover .icon i {
    color: $base-white;
  }
  
  .services .iconbox-pink:hover .icon path {
    fill: #e80368;
  }
  
  .services .iconbox-yellow i {
    color: #ffbb2c;
  }
  
  .services .iconbox-yellow:hover .icon i {
    color: $base-white;
  }
  
  .services .iconbox-yellow:hover .icon path {
    fill: #ffbb2c;
  }
  
  .services .iconbox-red i {
    color: #ff5828;
  }
  
  .services .iconbox-red:hover .icon i {
    color: $base-white;
  }
  
  .services .iconbox-red:hover .icon path {
    fill: #ff5828;
  }
  
  .services .iconbox-teal i {
    color: #11dbcf;
  }
  
  .services .iconbox-teal:hover .icon i {
    color: $base-white;
  }
  
  .services .iconbox-teal:hover .icon path {
    fill: #11dbcf;
  }
  
  /*--------------------------------------------------------------
  # Cta
  --------------------------------------------------------------*/
  .cta {
    background: $base-accent;
    background-size: cover;
    padding: 60px 0;
  }
  
  .cta h3 {
    color: $base-white;
    font-size: 28px;
    font-weight: 700;
  }
  
  .cta p {
    color: $base-white;
  }
  
  .cta .cta-btn {
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 10px 35px;
    border-radius: 5px;
    transition: 0.5s;
    margin-top: 10px;
    border: 2px solid $base-white;
    color: $base-white;
  }
  
  .cta .cta-btn:hover {
    background: $base-white;
    color: $base-accent;
  }
  
  
  /*--------------------------------------------------------------
  # Pricing
  --------------------------------------------------------------*/
  .pricing .box {
    padding: 40px 20px;
    text-align: center;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    background: #fefefe;
    box-shadow: 0px 5px 90px 0px rgba(110, 123, 131, 0.1);
  }
  
  .pricing .box h3 {
    font-weight: 400;
    padding: 15px;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 600;
    color: $base-dark-teal;
  }
  
  .pricing .box h4 {
    font-size: 42px;
    color: $base-accent;
    font-weight: 500;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 20px;
  }
  
  .pricing .box h4 sup {
    font-size: 20px;
    top: -15px;
    left: -3px;
  }
  
  .pricing .box h4 span {
    color: #bababa;
    font-size: 16px;
    font-weight: 300;
  }
  
  .pricing .box ul {
    padding: 0;
    list-style: none;
    color: $base-dark-teal;
    text-align: center;
    line-height: 20px;
    font-size: 14px;
  }
  
  .pricing .box ul li {
    padding-bottom: 16px;
  }
  
  .pricing .box ul .na {
    color: #ccc;
    text-decoration: line-through;
  }
  
  .pricing .box .btn-wrap {
    padding: 15px;
    text-align: center;
  }
  
  .pricing .box .btn-buy {
    display: inline-block;
    padding: 10px 40px;
    border-radius: 4px;
    color: $base-accent;
    transition: none;
    font-size: 14px;
    font-weight: 400;
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    transition: 0.3s;
    border: 2px solid $base-accent;
  }
  
  .pricing .box .btn-buy:hover {
    background: $base-accent;
    color: $base-white;
  }
  
  .pricing .featured {
    background: $base-accent;
  }
  
  .pricing .featured h3,
  .pricing .featured h4,
  .pricing .featured h4 span,
  .pricing .featured ul,
  .pricing .featured ul .na {
    color: $base-white;
  }
  
  .pricing .featured .btn-wrap {
    padding: 15px;
    text-align: center;
  }
  
  .pricing .featured .btn-buy {
    color: $base-white;
    border: 2px solid $base-white;
  }
  
  .pricing .featured .btn-buy:hover {
    background: $base-white;
    color: $base-accent;
  }
  
  /*--------------------------------------------------------------
  # Frequently Asked Questions
  --------------------------------------------------------------*/
  .faq .faq-list {
    padding: 0 100px;
  }
  
  .faq .faq-list ul {
    padding: 0;
    list-style: none;
  }
  
  .faq .faq-list li+li {
    margin-top: 15px;
  }
  
  .faq .faq-list li {
    padding: 20px;
    background: $base-white;
    border-radius: 4px;
    position: relative;
  }
  
  .faq .faq-list a {
    display: block;
    position: relative;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    padding: 0 0px;
    outline: none;
    cursor: pointer;
  }
  
  .faq .faq-list .icon-help {
    font-size: 24px;
    position: absolute;
    right: 0;
    left: 20px;
    color: #87c1ea;
  }
  
  .faq .faq-list .icon-show,
  .faq .faq-list .icon-close {
    font-size: 24px;
    position: absolute;
    right: 0;
    top: 0;
  }
  
  .faq .faq-list p {
    margin-bottom: 0;
    padding: 10px 0 0 0;
  }
  
  .faq .faq-list .icon-show {
    display: none;
  }
  
  .faq .faq-list a.collapsed {
    color: #343a40;
  }
  
  .faq .faq-list a.collapsed:hover {
    color: $base-accent;
  }
  
  .faq .faq-list a.collapsed .icon-show {
    display: inline-block;
  }
  
  .faq .faq-list a.collapsed .icon-close {
    display: none;
  }
  
  @media (max-width: 1200px) {
    .faq .faq-list {
      padding: 0;
    }
  }
  
  /*--------------------------------------------------------------
  # Contact
  --------------------------------------------------------------*/
  .contact .info {
    width: 100%;
    background: $base-white;
  }
  
  .contact .info i {
    font-size: 20px;
    color: $base-accent;
    float: left;
    width: 44px;
    height: 44px;
    background: #e3f0fa;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
  }
  
  .contact .info h4 {
    padding: 0 0 0 60px;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 5px;
    color: $base-dark-teal;
  }
  
  .contact .info p {
    padding: 0 0 0 60px;
    margin-bottom: 0;
    font-size: 14px;
    color: $base-blue;
  }
  
  .contact .info .email,
  .contact .info .phone {
    margin-top: 40px;
  }
  
  .contact .info .email:hover i,
  .contact .info .address:hover i,
  .contact .info .phone:hover i {
    background: $base-accent;
    color: $base-white;
  }
  
  .contact .php-email-form {
    width: 100%;
    background: $base-white;
  }
  
  .contact .php-email-form .form-group {
    padding-bottom: 8px;
  }
  
  .contact .php-email-form .error-message {
    display: none;
    color: $base-white;
    background: #ed3c0d;
    text-align: left;
    padding: 15px;
    font-weight: 600;
  }
  
  .contact .php-email-form .error-message br+br {
    margin-top: 25px;
  }
  
  .contact .php-email-form .sent-message {
    display: none;
    color: $base-white;
    background: #18d26e;
    text-align: center;
    padding: 15px;
    font-weight: 600;
  }
  
  .contact .php-email-form .loading {
    display: none;
    background: $base-white;
    text-align: center;
    padding: 15px;
  }
  
  .contact .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #18d26e;
    border-top-color: #eee;
    animation: animate-loading 1s linear infinite;
  }
  
  .contact .php-email-form input,
  .contact .php-email-form textarea {
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
    border-radius: 4px;
  }
  
  .contact .php-email-form input:focus,
  .contact .php-email-form textarea:focus {
    border-color: $base-accent;
  }
  
  .contact .php-email-form input {
    height: 44px;
  }
  
  .contact .php-email-form textarea {
    padding: 10px 12px;
  }
  
  .contact .php-email-form button[type=submit] {
    background: $base-accent;
    border: 0;
    padding: 10px 30px;
    color: $base-white;
    transition: 0.4s;
    border-radius: 4px;
  }
  
  .contact .php-email-form button[type=submit]:hover {
    background: $base-blue;
  }
  
  @keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  /*--------------------------------------------------------------
  # Breadcrumbs
  --------------------------------------------------------------*/
  .breadcrumbs {
    padding: 15px 0;
    background: #f8fbfe;
    min-height: 40px;
    margin-top: 72px;
  }
  
  @media (max-width: 992px) {
    .breadcrumbs {
      margin-top: 58px;
    }
  }
  
  .breadcrumbs h2 {
    font-size: 24px;
    font-weight: 300;
  }
  
  .breadcrumbs ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 14px;
  }
  
  .breadcrumbs ol li+li {
    padding-left: 10px;
  }
  
  .breadcrumbs ol li+li::before {
    display: inline-block;
    padding-right: 10px;
    color: #1a5e90;
    content: "/";
  }
  
  @media (max-width: 768px) {
    .breadcrumbs .d-flex {
      display: block !important;
    }
  
    .breadcrumbs ol {
      display: block;
    }
  
    .breadcrumbs ol li {
      display: inline-block;
    }
  }
  
  /*--------------------------------------------------------------
  # Footer
  --------------------------------------------------------------*/
  #footer {
    color: #444444;
    font-size: 14px;
    background: #f8fbfe;
    box-shadow: 0px 2px 15px rgba(18, 66, 101, 0.08);
  }
  
  #footer .footer-top {
    padding: 60px 0 30px 0;
    background: $base-white;
  }
  
  #footer .footer-top .footer-contact {
    margin-bottom: 30px;
  }
  
  #footer .footer-top .footer-contact h3 {
    font-size: 26px;
    margin: 0 0 10px 0;
    padding: 2px 0 2px 0;
    line-height: 1;
    font-weight: 300;
  }
  
  #footer .footer-top .footer-contact p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    font-family: "Raleway", sans-serif;
    color: #777777;
  }
  
  #footer .footer-top h4 {
    font-size: 16px;
    font-weight: bold;
    color: $base-dark-teal;
    position: relative;
    padding-bottom: 12px;
  }
  
  #footer .footer-top .footer-links {
    margin-bottom: 30px;
  }
  
  #footer .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  #footer .footer-top .footer-links ul i {
    padding-right: 2px;
    color: $base-accent;
    font-size: 18px;
    line-height: 1;
  }
  
  #footer .footer-top .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
  }
  
  #footer .footer-top .footer-links ul li:first-child {
    padding-top: 0;
  }
  
  #footer .footer-top .footer-links ul a {
    color: #777777;
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
  }
  
  #footer .footer-top .footer-links ul a:hover {
    text-decoration: none;
    color: $base-accent;
  }
  
  #footer .footer-newsletter {
    font-size: 15px;
  }
  
  #footer .footer-newsletter h4 {
    font-size: 16px;
    font-weight: bold;
    color: #444444;
    position: relative;
    padding-bottom: 12px;
  }
  
  #footer .footer-newsletter form {
    margin-top: 30px;
    background: $base-white;
    padding: 6px 10px;
    position: relative;
    border-radius: 5px;
    text-align: left;
    border: 1px solid #cde5f6;
  }
  
  #footer .footer-newsletter form input[type=email] {
    border: 0;
    padding: 4px 8px;
    width: calc(100% - 100px);
  }
  
  #footer .footer-newsletter form input[type=submit] {
    position: absolute;
    top: -1px;
    right: -2px;
    bottom: -1px;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 20px 2px 20px;
    background: $base-accent;
    color: $base-white;
    transition: 0.3s;
    border-radius: 0 5px 5px 0;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  }
  
  #footer .footer-newsletter form input[type=submit]:hover {
    background: $base-blue;
  }
  
  #footer .credits {
    padding-top: 5px;
    font-size: 13px;
    color: #444444;
  }
  
  #footer .social-links a {
    font-size: 18px;
    display: inline-block;
    background: $base-accent;
    color: $base-white;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 5px;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
  }
  
  #footer .social-links a:hover {
    background: $base-blue;
    color: $base-white;
    text-decoration: none;
  }

  .bg-light-teal{
    background-color: $base-light-teal;
  }

  .hidden{
    visibility: hidden;
  }


  .menu-btn{
    border: none !important;
    background-color: transparent !important;
    font-weight: bold;
  }